<template>
  <van-cell span="12" :border="false">
    <router-link
      :to="{ name: 'OrderDetail', params: { oid: item.oid, type: 1 } }"
    >
      <div class="content">
        <div v-for="goods in item.items" :key="goods.iid" class="pic">
          <img :src="goods.pic" />
        </div>
        <div v-if="item.items.length === 1" class="info van-multi-ellipsis--l3">
          {{ item.items[0].title }}
        </div>

        <span v-if="item.type !== 1" class="badge">福袋</span>
      </div>
      <div class="count">
        <div class="price">￥{{ item.payment }}</div>
        <div class="total">共{{ item.quantity }}件</div>
      </div>
    </router-link>

    <OrderOperate :item="item" />
  </van-cell>
</template>

<script>
import OrderOperate from "@/components/OrderOperate.vue";
export default {
  name: "Order",
  props: {
    item: Object,
  },
  components: {
    OrderOperate,
  },
  data() {
    return {};
  },
};
</script>
