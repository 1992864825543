<template>
  <div class="orders">
    <van-tabs class="tabs" type="card" v-model="tabid" @change="onChange">
      <van-tab
        v-for="tab in tabs"
        :title="tab.tab_name"
        :key="tab.tab_id"
        :name="tab.tab_id"
      >
      </van-tab>

      <van-empty v-if="!orders || orders.length < 1" description="没有订单" />
      <van-pull-refresh v-model="pullLoading" @refresh="refresh">
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getOrders"
          class="order-list"
        >
          <Order :item="item" v-for="(item, i) in orders" :key="i" />
        </van-list>
      </van-pull-refresh>
    </van-tabs>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import Order from "@/components/Order.vue";
export default {
  name: "Orders",
  components: {
    Order,
  },
  data() {
    return {
      orders: [],
      pullLoading: false,
      loading: false,
      finished: false,
      page: 0,
      tabid: -1,
      tabs: [
        {
          tab_id: -1,
          tab_name: "全部",
        },
        {
          tab_id: 1,
          tab_name: "待付款",
        },
        {
          tab_id: 3,
          tab_name: "待收货",
        },
        {
          tab_id: 4,
          tab_name: "已完成",
        },
      ],
    };
  },
  created() {
    let tabid = this.$route.params.tabid;
    this.tabid = tabid ? tabid : -1;
    console.log(this.tabid);
  },
  mounted() {
    //删除订单
    Bus.$off("removeOrderOnbus");
    Bus.$on("removeOrderOnbus", (oid) => {
      this.removeOrder(oid);
    });
    //取消订单
    Bus.$off("cancelOrderOnbus");
    Bus.$on("cancelOrderOnbus", (res) => {
      this.cancelOrder(res);
    });
    //确认收货
    Bus.$off("receiveOnbus");
    Bus.$on("receiveOnbus", (oid) => {
      this.receive(oid);
    });
    //超时未支付
    Bus.$off("timeOverOnbus");
    Bus.$on("timeOverOnbus", (oid) => {
      this.timeOver(oid);
    });
  },
  methods: {
    onChange(id) {
      this.page = 0;
      this.finished = false;
      this.tabid = id;
      this.getOrders();
    },
    //删除订单
    removeOrder(oid) {
      for (let i = 0; i < this.orders.length; i++) {
        if (oid === this.orders[i].oid) {
          this.orders.splice(i, 1);
          break;
        }
      }
    },
    //订单超时付款关闭
    timeOver(oid) {
      for (let i = 0; i < this.orders.length; i++) {
        if (oid === this.orders[i].oid) {
          this.$set(this.orders[i], "status", 5);
          break;
        }
      }
    },
    //取消订单
    cancelOrder(res) {
      for (let i = 0; i < this.orders.length; i++) {
        if (res.data.oid === this.orders[i].oid) {
          this.$set(this.orders, i, res.data);
          break;
        }
      }
    },
    //确认收货
    receive(res) {
      for (let i = 0; i < this.orders.length; i++) {
        if (res.data.oid === this.orders[i].oid) {
          this.$set(this.orders, i, res.data);
          break;
        }
      }
    },
    refresh() {
      this.page = 0;
      this.getOrders();
    },
    getOrders() {
      this.$ajax
        .post("/order/order/index/", {
          filter: this.tabid,
          page_no: this.page,
          page_size: 5,
        })
        .then((res) => {
          if (this.page === 0) {
            this.orders = res.data;
          } else {
            if (res.data) {
              this.orders = [...this.orders, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
          this.pullLoading = false;
        });
    },
  },
};
</script>
